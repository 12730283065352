@import '@/styles/theme/variables.scss';

.sideNavigationPanel {
    ul {
      list-style-type: none;
  
      li {
        margin-bottom: $token-spacing-2;
      }
    }

    .sideNavigationPanelSelect {
      .outerDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include menuLinkFocused;

        svg {
          margin-top: $token-spacing-2;
          margin-right: $token-spacing-4;
        }

        &.selected {
          background-color: $sideNavigationPanelSelect-selected-background;
        }

        &.usbDashboardDivCssClass {
          &.selected{
            background: $token-background-transparent !important;
          }
        }
    
        &.elavonDashboardDivCssClass {
          &.selected{
            background: $token-background-transparent !important;
          }
        }

        .linkWrapper {
          width: -webkit-fill-available;

          a {
            width: -webkit-fill-available;
          }
        }
      }
  
      .outerLi-1 {
        .sideNavigationPanelSelectInner {
          margin-top: $token-spacing-1;

          &.expanded {
            display: block;

            a {
              padding: $token-spacing-6px $token-spacing-2 $token-spacing-6px $token-spacing-30px;
            }

            span {
              padding: $token-spacing-0;
            }
          }
        }
      }

      .outerLi-2 {
        a {
          color: $token-text-color-secondary;
          font-weight: $token-font-weight-reg;
        }
      }
    }

    @include menuItemLevelStyle(1) {
      a {
        @include menuLink;
      }
    }

    // Indent toc elements by n
    @for $n from 2 through 10 {
      @include menuItemLevelStyle($n) {
        padding: $token-spacing-6px $token-spacing-2 $token-spacing-6px $token-spacing-18px + ($n - 1) * $token-spacing-10px !important;
      }
    }
  }
